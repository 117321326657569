import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Content from '../Content'
import Button from '../Button'

class TextWithImageCtaAus extends PureComponent {
  render() {
    const {
      theme,
      packshot,
      subscribeUrl,
      loginOnClick,
      openSubscribeLinkInNewTab
    } = this.props
    return (
      <div
        className={classNames(
          'TextWithImageCta',
          theme && `TextWithImageCta-${theme}`
        )}
      >
        <Content center width="medium" gutter>
          <img
            className="TextWithImageCtaCImage"
            src={packshot}
            alt={packshot}
          />
          <div className="TextWithImageCtaContent">
            <h3 className="TextWithImageCtaHeading">
              Get all the benefits of membership when you subscribe to{' '}
              <i>The Australian</i>
            </h3>
            <p className="TextWithImageCtaSubHeading">
              Already a subscriber? Log in below
            </p>
            <div className="TextWithImageCtaButtons">
              <Button
                target={openSubscribeLinkInNewTab ? '_blank' : '_self'}
                secondary
                href={subscribeUrl}
              >
                Subscribe
              </Button>
              <Button login onClick={loginOnClick}>
                Log in
              </Button>
            </div>
          </div>
        </Content>
      </div>
    )
  }
}

TextWithImageCtaAus.propTypes = {
  packshot: PropTypes.string,
  subscribeUrl: PropTypes.string,
  loginOnClick: PropTypes.func,
  theme: PropTypes.string,
  openSubscribeLinkInNewTab: PropTypes.bool
}

export default TextWithImageCtaAus
